import React from "react";
import styles from "./CoownerhipsCoownersDetail.module.scss";
import AvatarLogo from "../../assets/images/avatar.svg"
import Button from "@material-ui/core/Button";
import CarLogo from "../../assets/images/car.svg"

import firstLogo from "../../assets/icons/01.svg"
import secondLogo from "../../assets/icons/02.svg"
import thirdLogo from "../../assets/icons/03.svg"
import forthLogo from "../../assets/icons/04.svg"

const colorsImage = [firstLogo, secondLogo, thirdLogo, forthLogo]
const colors = ['#009d69', '#ff842c', '#a42cff', '#2c77ff']

function logoSelector(color) {
  let index = colors.indexOf(color)
  return colorsImage[index]
}

function CoownerhipsCoownersDetail({ coowners, history }) {
  return (
    <div className={styles.container}>
      <div style={{fontSize: 20}}>Coowners</div>
      <div style={{marginTop: 12}}>Here are the coowners this coownership has</div>
      <div className={styles.tableContainer}>
        {
          coowners.map((el, index) => {
            return (
              <div className={styles.row} onClick={() => history.push(`/users/${el.userId}`)}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                  <div style={{marginRight: 12, width: 20}}>{index + 1}.</div>
                  <img style={{marginRight: 20}} src={logoSelector(el.color)}/>
                  <div>{el.name}</div>
                </div>
                <div>Status: {el.state}</div>
                <div>{el.isInitiator ? "Initiator" : "Coowner"}</div>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default CoownerhipsCoownersDetail;