import React, { useState } from "react";
import RouterConfig from "./RouterConfig";
import IntlConfig from "./IntlConfig";
import { GlobalStateProvider } from "./GlobalStateContext";
import AppThemeProvider from "./ThemeProvider";

const App = () => {
  return (
    <GlobalStateProvider>
      <AppThemeProvider>
        <IntlConfig>
          <RouterConfig />
        </IntlConfig>
      </AppThemeProvider>
    </GlobalStateProvider>
  );
};

export default App;
