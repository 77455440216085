const root = "https://api.pingonio.com/";
// const localIP = "http://192.168.1.6:5000/";
const localIP = "http://localhost:5000/";
const isLocal = false;
export const apiUrl = isLocal ? localIP : root;

export const self = {
  auth: {
    login: `${apiUrl}oauth/token`,
  },
  users: {
    download: `${apiUrl}admin/user-excel`,
    getAll: `${apiUrl}admin/users`,
    getOne: `${apiUrl}admin/users`,
    delete: `${apiUrl}admin/delete-user`,
    extend: `${apiUrl}admin/extend-membership`,
  },
  orders: {
    getAll: `${apiUrl}admin/orders`,
    getOne: `${apiUrl}admin/orders`,
  },
  books: {
    getAll: `${apiUrl}admin/books`,
    getOne: `${apiUrl}admin/book`,
  },
  articles: {
    getAll: `${apiUrl}admin/articles`,
    getOne: `${apiUrl}admin/article`,
  },
  discounts: {
    getAll: `${apiUrl}admin/discounts`,
  },
  coownerships: {
    getAll: `${apiUrl}admin/coownership`,
    getOne: `${apiUrl}admin/coownership`,
    delete: `${apiUrl}admin/delete-coownership`
  },
  detail: `${apiUrl}admin/books`,
  logs: `${apiUrl}admin/logs`
};

export default self;
