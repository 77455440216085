import urls from "../../constants/urls";
import { appendToUrl } from "../../handlers/util";
import { Del, Get, Post } from "../../handlers/axios-wrapper";


export function login(payload) {
  return Post(urls.auth.login, payload);
}

export function getCoOwnerShipById(id) {
  return Get(appendToUrl(urls.coownerships.getOne, id));
}
export function getCoOwnerShipsList() {
  return Get(urls.coownerships.getAll);
}
export function getUserById(id) {
  return Get(appendToUrl(urls.users.getOne, id));
}
export function getOrderById(id) {
  return Get(appendToUrl(urls.orders.getOne, id));
}
export function getOrdersList() {
  return Get(urls.orders.getAll);
}

export function addBook(payload) {
  return Post(urls.books.getOne, {...payload});
}
export function getBookById(id) {
  return Get(appendToUrl(urls.books.getOne, id));
}
export function getBooksList() {
  return Get(urls.books.getAll);
}

export function addArticle(payload) {
  return Post(urls.articles.getOne, {...payload});
}
export function getArticlesById(id) {
  return Get(appendToUrl(urls.articles.getOne, id));
}
export function getArticlesList() {
  return Get(urls.articles.getAll);
}

export function addDiscount(payload) {
  return Post(urls.discounts.getAll, {...payload});
}
export function removeDiscount(id) {
  return Del(appendToUrl(urls.discounts.getAll, id));
}
export function getDiscountsList() {
  return Get(urls.discounts.getAll);
}

export function getUsersList() {
  return Get(urls.users.getAll);
}
export function downloadExcel() {
  return Get(urls.users.getAll);
}
export function extendMembership(payload) {
  return Post(urls.users.extend, payload);
}
export function removeUser(payload) {
  return Post(urls.users.delete, payload);
}
export function removeCoownership(payload) {
  return Post(urls.coownerships.delete, payload);
}
export function getLogs() {
  return Get(urls.logs);
}
export function getOverview() {
  return Get(urls.detail);
}