import React from "react";
import styles from "./OrderCardList.module.scss";
import AvatarLogo from "../../assets/images/avatar.svg"
import Button from "@material-ui/core/Button";
import { normalizeDate, makeNumbersFarsi, priceNormalizer } from "../../handlers/util";

const options = { year: 'numeric', month: 'long', day: 'numeric' };

function OrderCardList({ hasVisit, id, history, name, email, cellphone, birthday, code, onDeleteClick, date, price }) {
  let newDate = new Date(date)
  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <div className={styles.leftInner}>
          <b>{name}</b>
          <div>تاریخ: {newDate.toLocaleDateString('fa-IR', options)}</div>
          {
            !hasVisit &&
            <> 
              <div>Phone Number: {cellphone ? cellphone : "Not defined"}</div>
              <div>Birthday: {birthday ? normalizeDate(birthday) : "Not defined"}</div>
            </>
          }
        </div>
        <div className={styles.rightInner}>
          <div>قیمت : {makeNumbersFarsi(priceNormalizer(price))}</div>
          <Button onClick={() => history.push(`/users/${id}`)} style={{minWidth: 160}} color="primary" variant="contained">
            بازدید
          </Button>
        </div>
      </div>
    </div>
  )
}


export default OrderCardList;