import React from "react";
import styles from "./UserCardList.module.scss";
import AvatarLogo from "../../assets/images/avatar.svg"
import Button from "@material-ui/core/Button";
import { normalizeDate } from "../../handlers/util";

function UserCardList({ hasVisit, id, history, name, email, cellphone, birthday, code, onDeleteClick, confirmed }) {
  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <div className={styles.leftInner}>
          <b>{name}</b>
          <div>Email: {email}</div>
          {
            !hasVisit &&
            <> 
              <div>Phone Number: {cellphone ? cellphone : "Not defined"}</div>
              <div>Birthday: {birthday ? normalizeDate(birthday) : "Not defined"}</div>
            </>
          }
        </div>
        <div className={styles.rightInner}>
          {
          hasVisit ? 
            <Button onClick={() => history.push(`/users/${id}`)} style={{minWidth: 160}} color="primary" variant="contained">
              بازدید
            </Button>
            : 
            <Button onClick={onDeleteClick} style={{minWidth: 160, backgroundColor: "#b00020", color: "white"}} variant="contained">
              Delete
            </Button>  
          }
        </div>
      </div>
    </div>
  )
}


export default UserCardList;