import React from "react";
import styles from "./DiscountCard.module.scss";
import AvatarLogo from "../../assets/images/avatar.svg"
import Button from "@material-ui/core/Button";
import { normalizeDate, makeNumbersFarsi, priceNormalizer } from "../../handlers/util";
import { removeDiscount } from "../../services/api/api";

function DiscountCard({ id, price, percent, code }) {
  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <div className={styles.leftInner}>
          <b>کد: {code}</b>
          <div style={{textAlign: "right", direction: "rtl"}}>مقدار تخفیف : {percent} {price}</div>
        </div>
        <div className={styles.rightInner}>
          <div></div>
          <Button onClick={() => removeDiscount(id)} style={{minWidth: 160}} color="secondary" variant="contained">
            حذف
          </Button>
        </div>
      </div>
    </div>
  )
}


export default DiscountCard;