import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/Layout/Header/Header";
import { useIntl } from "react-intl";
import CoownershipCard from "../../components/CoownershipCard/CoownershipCard";
import UserCard from "../../components/UserCard/UserCard";
import UserMembershipDetail from "../../components/UserMembershipDetail/UserMembershipDetail";
import UserCoownerhipsDetail from "../../components/UserCoownerhipsDetail/UserCoownerhipsDetail";
import {
  extendMembership,
  getUserById,
  removeUser,
  addBook
} from "../../services/api/api";
import TextField from "@material-ui/core/TextField";
import ProfileImage from "../../assets/icons/upload.png";
import { Button } from "@material-ui/core";
import { makeNumbersLatin } from "../../handlers/util";

export const AddBook = ({ history, match }) => {
  const intl = useIntl();
  const formRef = useRef();

  const [loading, setLoading] = useState(false);
  const [bookImage, setBookImage] = useState(null);
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [enTitle, setenTitle] = useState("");
  const [abstract, setAbstract] = useState("");
  const [translator, setTranslator] = useState("");
  const [pages, setPages] = useState("");
  const [year, setYear] = useState("");
  const [time, setTime] = useState("");
  const [authorName, setAuthorName] = useState("");
  const [authorImage, setAuthorImage] = useState(null);
  const [authorTwitter, setAuthorTwitter] = useState("");
  const [authorWebsite, setAuthorWebsite] = useState("");
  const [authorLinkedin, setAuthorLinkedin] = useState("");
  const [authorDescription, setAuthorDescription] = useState("");
  const [first, setFirst] = useState("");
  const [price, setPrice] = useState("");
  const [subject, setSubject] = useState("");

  const [snackbarConfig, setSnackbarConfig] = useState({
    message: "",
    open: false,
    action: null
  });
  const onBookImageUpload = e => {
    let image = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setBookImage(reader.result);
    };
    reader.readAsDataURL(image);
  };
  const onAuthorImageUpload = e => {
    let image = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setAuthorImage(reader.result);
    };
    reader.readAsDataURL(image);
  };
  const handleSubmitClick = e => {
    e.preventDefault()
    if (!formRef.current.checkValidity()) {
      console.log("hey");
    } else {
      addBook({
        image: bookImage,
        authors: [
          {
            name: authorName,
            twitter: authorTwitter,
            web: authorWebsite,
            linkedin: authorLinkedin,
            bio: authorDescription,
          }
        ],
        name: makeNumbersLatin(name),
        title,
        enTitle,
        abstract,
        translator,
        pages,
        year,
        time,
        authorImage,
        first,
        price: makeNumbersLatin(price),
        subject
      })
    }
  };
  return (
    <div>
      <Header history={history} />

      <b
        style={{
          width: "100%",
          maxWidth: 900,
          margin: "auto",
          paddingTop: 32,
          display: "flex",
          flexDirection: "row-reverse",
          justifyContent: "space-between",
          flexWrap: "wrap"
        }}
      >
        اضافه کردن کتاب
      </b>
      <form
        ref={formRef}
        onSubmit={handleSubmitClick}
        style={{
          width: "100%",
          maxWidth: 900,
          margin: "auto",
          display: "flex",
          flexDirection: "row-reverse",
          justifyContent: "space-between",
          flexWrap: "wrap"
        }}
      >
        <TextField
          onChange={e => setTitle(e.target.value)}
          id="outlined-basic"
          type="text"
          label="تیتر"
          variant="outlined"
          required
          style={{
            marginTop: 24,
            direction: "rtl",
            width: "calc(50% - 16px)",
            textAlign: "right"
          }}
        />
        <TextField
          onChange={e => setName(e.target.value)}
          InputProps={{ style: { direction: "rtl", textAlign: "right" } }}
          id="outlined-basic"
          type="text"
          label="نام کتاب (انگلیسی)"
          variant="outlined"
          required
          style={{
            marginTop: 24,
            direction: "rtl",
            width: "calc(50% - 16px)",
            textAlign: "right"
          }}
        />
        <TextField
          onChange={e => setAbstract(e.target.value)}
          id="outlined-basic"
          type="text"
          label="متن اول"
          variant="outlined"
          required
          style={{
            marginTop: 24,
            direction: "rtl",
            width: "calc(100%)",
            textAlign: "right"
          }}
        />
        <TextField
          onChange={e => setTranslator(e.target.value)}
          InputProps={{ style: { direction: "rtl", textAlign: "right" } }}
          id="outlined-basic"
          type="text"
          label="نام مترجم"
          variant="outlined"
          required
          style={{
            marginTop: 24,
            direction: "rtl",
            width: "calc(50% - 16px)",
            textAlign: "right"
          }}
        />
        <TextField
          onChange={e => setPages(e.target.value)}
          id="outlined-basic"
          type="text"
          label="تعداد صفحات"
          variant="outlined"
          required
          style={{
            marginTop: 24,
            direction: "rtl",
            width: "calc(50% - 16px)",
            textAlign: "right"
          }}
        />
        <TextField
          onChange={e => setTime(e.target.value)}
          InputProps={{ style: { direction: "rtl", textAlign: "right" } }}
          id="outlined-basic"
          type="text"
          label="نوبت چاپ"
          variant="outlined"
          required
          style={{
            marginTop: 24,
            direction: "rtl",
            width: "calc(50% - 16px)",
            textAlign: "right"
          }}
        />
        <TextField
          onChange={e => setPrice(e.target.value)}
          InputProps={{ style: { direction: "rtl", textAlign: "right" } }}
          id="outlined-basic"
          type="text"
          label="قیمت"
          multiline
          variant="outlined"
          required
          style={{
            marginTop: 24,
            direction: "rtl",
            width: "calc(50% - 16px)",
            textAlign: "right"
          }}
        />
        <TextField
          onChange={e => setYear(e.target.value)}
          InputProps={{ style: { direction: "rtl", textAlign: "right" } }}
          id="outlined-basic"
          type="text"
          label="سال چاپ"
          multiline
          variant="outlined"
          required
          style={{
            marginTop: 24,
            direction: "rtl",
            width: "calc(50% - 16px)",
            textAlign: "right"
          }}
        />
        <TextField
          onChange={e => setSubject(e.target.value)}
          InputProps={{ style: { direction: "rtl", textAlign: "right" } }}
          id="outlined-basic"
          type="text"
          label="موضوع"
          multiline
          variant="outlined"
          required
          style={{
            marginTop: 24,
            direction: "rtl",
            width: "calc(50% - 16px)",
            textAlign: "right"
          }}
        />
        <TextField
          onChange={e => setFirst(e.target.value)}
          InputProps={{ style: { direction: "rtl", textAlign: "right" } }}
          id="outlined-basic"
          type="text"
          label="فصل اول کتاب"
          multiline
          rows="5"
          variant="outlined"
          required
          style={{
            marginTop: 24,
            direction: "rtl",
            width: "calc(100%)",
            textAlign: "right"
          }}
        />
        <b
          style={{
            width: "100%",
            textAlign: "right",
            marginBottom: 8,
            marginTop: 20
          }}
        >
          اطلاعات نویسنده
        </b>
        <TextField
          onChange={e => setAuthorName(e.target.value)}
          InputProps={{ style: { direction: "rtl", textAlign: "right" } }}
          id="outlined-basic"
          type="text"
          label="نام نویسنده"
          variant="outlined"
          required
          style={{
            marginTop: 24,
            direction: "rtl",
            width: "calc(50% - 16px)",
            textAlign: "right"
          }}
        />
        <TextField
          onChange={e => setAuthorTwitter(e.target.value)}
          InputProps={{ style: { direction: "rtl", textAlign: "right" } }}
          id="outlined-basic"
          type="text"
          label="آدرس توییتر نویسنده"
          variant="outlined"
          required
          style={{
            marginTop: 24,
            direction: "rtl",
            width: "calc(50% - 16px)",
            textAlign: "right"
          }}
        />
        <TextField
          onChange={e => setAuthorLinkedin(e.target.value)}
          InputProps={{ style: { direction: "rtl", textAlign: "right" } }}
          id="outlined-basic"
          type="text"
          label="آدرس لینکداین نویسنده"
          variant="outlined"
          required
          style={{
            marginTop: 24,
            direction: "rtl",
            width: "calc(50% - 16px)",
            textAlign: "right"
          }}
        />
        <TextField
          onChange={e => setAuthorWebsite(e.target.value)}
          InputProps={{ style: { direction: "rtl", textAlign: "right" } }}
          id="outlined-basic"
          type="text"
          label="آدرس سایت نویسنده"
          variant="outlined"
          required
          style={{
            marginTop: 24,
            direction: "rtl",
            width: "calc(50% - 16px)",
            textAlign: "right"
          }}
        />
        <TextField
          onChange={e => setAuthorDescription(e.target.value)}
          InputProps={{ style: { direction: "rtl", textAlign: "right" } }}
          id="outlined-basic"
          type="text"
          label="توضیح راجع به نویسنده"
          variant="outlined"
          required
          style={{
            marginTop: 24,
            direction: "rtl",
            width: "calc(100%)",
            textAlign: "right"
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            alignItems: "center",
            width: "calc(50% - 16px)",
            marginTop: 20
          }}
        >
          <div>: تصویر کتاب</div>
          <div>
            <input
              name="bookImage"
              accept="image/*"
              style={{ display: "none" }}
              id="raised-button-file"
              type="file"
              onChange={onBookImageUpload}
            />
            <label htmlFor="raised-button-file">
              {<img style={{ width: 70, height: 70 }} src={ProfileImage} />}
            </label>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            alignItems: "center",
            width: "calc(50% - 16px)",
            marginTop: 20
          }}
        >
          <div>: تصویر نویسنده</div>
          <div>
            <input
              name="authoImage"
              accept="image/*"
              style={{ display: "none" }}
              id="raised-button-file-2"
              type="file"
              onChange={onAuthorImageUpload}
            />
            <label htmlFor="raised-button-file-2">
              {<img style={{ width: 70, height: 70 }} src={ProfileImage} />}
            </label>
          </div>
        </div>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          fullWidth
          style={{ height: 48 }}
        >
          ارسال
        </Button>
      </form>
    </div>
  );
};

export default AddBook;
