import axios from "axios";
import { apiUrl } from "../config";
// import Toast from "../components/toast";
import urls from "../constants/urls";

const req = axios.create({
  baseURL: apiUrl,
  timeout: 20000,
});

req.interceptors.request.use(
  (config) => {
    const locale = localStorage.getItem("locale") || "en";
    const token = localStorage.getItem("accessToken");

    config.headers["Accept-Language"] = locale;
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => console.log(error)
);

req.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    switch (error.response.status) {
      case 401:
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("expiresIn");
        window.location.href = "/";
        break;
      default:
        // Toast.error('An unknown error happened');
        break;
    }
    return Promise.reject(error);
  }
);

export const Post = async (url, data) => {
  const expiresIn = localStorage.getItem("expiresIn");
  const accessToken = localStorage.getItem("accessToken") || null;
  const locale = localStorage.getItem("locale") || "en";
  let headers = {
    "Accept-Language": locale,
  };
  if (accessToken) {
    if (expiresIn && Date.now() + 6000 > expiresIn) {
      const refreshToken = localStorage.getItem("refreshToken");
      let res = await req.post(urls.auth.login, {
        grant_type: "refresh_token",
        refresh_token: refreshToken,
      });
      localStorage.setItem("accessToken", res.data.access_token);
      localStorage.setItem("refreshToken", res.data.refresh_token);
      localStorage.setItem("expiresIn", Date.now() + res.data.expires_in);
      headers["Authorization"] = `bearer ${res.data.access_token}`;

      return req.post(url, data, {
        headers,
      });
    } else {
      headers["Authorization"] = `bearer ${accessToken}`;
      return req.post(url, data, {
        headers,
      });
    }
  } else {
    return req.post(url, data, {
      headers,
    });
  }
};

export const Get = async (url) => {
  const expiresIn = localStorage.getItem("expiresIn");
  const accessToken = localStorage.getItem("accessToken");
  const locale = localStorage.getItem("locale") || "en";
  let headers = {
    "Accept-Language": locale,
  };
  if (accessToken) {
    if (expiresIn && Date.now() + 6000 > expiresIn) {
      const refreshToken = localStorage.getItem("refreshToken");
      let res = await req.post(urls.auth.login, {
        grant_type: "refresh_token",
        refresh_token: refreshToken,
      });
      localStorage.setItem("accessToken", res.data.access_token);
      localStorage.setItem("refreshToken", res.data.refresh_token);
      localStorage.setItem("expiresIn", Date.now() + res.data.expires_in);
      headers["Authorization"] = `bearer ${res.data.access_token}`;

      return req.get(url, {
        headers,
      });
    } else {
      headers["Authorization"] = `bearer ${accessToken}`;
      return req.get(url, {
        headers,
      });
    }
  } else {
    return req.get(url, {
      headers,
    });
  }
};

export const Del = async (url) => {
  const expiresIn = localStorage.getItem("expiresIn");
  const accessToken = localStorage.getItem("accessToken");
  const locale = localStorage.getItem("locale") || "en";
  let headers = {
    "Accept-Language": locale,
  };
  if (accessToken) {
    if (expiresIn && Date.now() + 6000 > expiresIn) {
      const refreshToken = localStorage.getItem("refreshToken");
      let res = await req.post(urls.auth.login, {
        grant_type: "refresh_token",
        refresh_token: refreshToken,
      });
      localStorage.setItem("accessToken", res.data.access_token);
      localStorage.setItem("refreshToken", res.data.refresh_token);
      localStorage.setItem("expiresIn", Date.now() + res.data.expires_in);

      headers["Authorization"] = `bearer ${res.data.access_token}`;

      return req.delete(url, {
        headers,
      });
    } else {
      headers["Authorization"] = `bearer ${accessToken}`;
      return req.delete(url, {
        headers,
      });
    }
  } else {
    return req.delete(url, {
      headers,
    });
  }
};
