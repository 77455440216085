import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/Layout/Header/Header";
import { useIntl } from "react-intl";
import CoownershipCard from "../../components/CoownershipCard/CoownershipCard";
import UserCard from "../../components/UserCard/UserCard";
import UserMembershipDetail from "../../components/UserMembershipDetail/UserMembershipDetail";
import UserCoownerhipsDetail from "../../components/UserCoownerhipsDetail/UserCoownerhipsDetail";
import { extendMembership, getUserById, removeUser } from "../../services/api/api";

export const UserList = ({ history, match }) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [extendLoading, setExtendLoading] = useState(false);
  const [membership, setMembership] = useState(null);
  const [user, setUser] = useState({});
  const [coownerships, setCoownerships] = useState([]);
  const containerRef = useRef();
  const [snackbarConfig, setSnackbarConfig] = useState({
    message: "",
    open: false,
    action: null,
  });
  useEffect(() => {
    getUserById(match.params.id).then((res) => {
      setUser(res.data)
      setLoading(false)
    }).catch((err) => {
      // history.goBack()
    })
  }, [])
  const extendClickHandler = (time) => {
    setExtendLoading(true)
    extendMembership({time, userId: match.params.id}).then((res) => {
      let date = new Date(membership) 
      date.setMonth(date.getMonth() + time)
      setMembership(date)
      setExtendLoading(false)
    })
  }
  const deleteClickHandler = (time) => {
    setDeleteLoading(true)
    removeUser({userId: match.params.id}).then((res) => {
      setDeleteLoading(false)
      history.replace("/users")
    })
  }
  if(loading){
    return (
      <div>
        <Header history={history}/>
        <div style={{width: "100%", textAlign: "center", marginTop: 120}}>
        درحال بارگذاری
        </div>
      </div>
    )
  }
  return (
    <div>
      <Header history={history}/>
      <div style={{padding: 24}}>
        <UserCard confirmed={false} name={user.firstName + " " + user.lastName} email={!loading && user.user.username} country={user.country} cellphone={user.cellphone} birthday={user.birthday} onDeleteClick={deleteClickHandler}/>
      </div>
    </div>
  );
};

export default UserList;
