import React from "react";
import Messages from './messages'
import { useGlobalState } from "./GlobalStateContext";
import { IntlProvider } from 'react-intl';

const IntlConfig = ({children}) => {
  const globalState = useGlobalState()
  return (
    <IntlProvider locale={globalState.locale} messages={Messages[globalState.locale]}>
      {children}
    </IntlProvider>
  );
};

export default IntlConfig;
