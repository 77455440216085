import React from "react";
import styles from "./UserCoownerhipsDetail.module.scss";
import AvatarLogo from "../../assets/images/avatar.svg"
import Button from "@material-ui/core/Button";
import CarLogo from "../../assets/images/car.svg"

function UserCoownerhipsDetail({ coownerships, history }) {
  return (
    <div className={styles.container}>
      <div style={{fontSize: 20}}>Coownerships: </div>
      <div style={{marginTop: 12}}>Here are the coownerships this user has</div>
      <div className={styles.tableContainer}>
        {
          coownerships.map((el, index) => {
            return (
              <div className={styles.row} onClick={() => history.push(`/coownerships/${el.id}`)}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                  <div style={{marginRight: 12}}>{index + 1}.</div>
                  <img style={{marginRight: 20}} src={CarLogo}/>
                  <div>{el.Asset.type}</div>
                </div>
                <div>type: {el.type}</div>
                <div>Status: {el.state}</div>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default UserCoownerhipsDetail;