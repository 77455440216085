import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/Layout/Header/Header";
import { useIntl } from "react-intl";
import CoownershipCard from "../../components/CoownershipCard/CoownershipCard";
import UserCard from "../../components/UserCard/UserCard";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { login } from "../../services/api/api";
import { MySnackbar } from "../../components/MySnackbar/MySnackbar";
import { injectErrorCode } from "../../handlers/inject-error";
import Loading from "../../components/Loading/Loading";

export const Login = ({ history }) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [snackbarConfig, setSnackbarConfig] = useState({
    message: "",
    open: false,
    action: null,
  });
  const clickHandler = () => {
    setLoading(true)
    login({
      username: email,
      password: password,
      grant_type: "password",
      isAdmin: true
    }).then((res) => {
      setLoading(false)
      localStorage.setItem("accessToken", res.data.access_token);
      localStorage.setItem("refreshToken", res.data.refresh_token);
      localStorage.setItem( "expiresIn", Date.now() + res.data.expires_in );
      history.replace("/users")
    }).catch((err) => {
      setLoading(false)
      setSnackbarConfig({
        open: true,
        action: null,
        message: intl.formatMessage({id: injectErrorCode(err.response.data)}),
        showIcon: false,
        type: "error",
      });
    })
  };
  return (
    <div>
        <MySnackbar
          {...snackbarConfig}
          setOpen={(open) => setSnackbarConfig({ ...snackbarConfig, open })}
        />
      <div
        style={{
          padding: 24,
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: 360,
            display: "flex",
            flexDirection: "column",
            padding: 18,
            paddingTop: 24,
            paddingBottom: 32,
            border: "solid 1px #111",
          }}
        >
          <TextField
            onChange={(e) => setEmail(e.target.value)}
            id="outlined-basic"
            label="نام کاربری"
            variant="outlined"
            style={{ marginTop: 24, direction: "rtl" }}
          />
          <TextField
            onChange={(e) => setPassword(e.target.value)}
            id="outlined-basic"
            type="password"
            label="رمز عبور"
            variant="outlined"
            style={{ marginTop: 24, direction: "rtl" }}
          />
          <Button
            style={{ marginTop: 24, height: 48 }}
            fullWidth
            variant="contained"
            color="primary"
            onClick={clickHandler}
          >
            {
              loading ? <Loading/> : "ورود"
            }
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Login;
