import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/Layout/Header/Header";
import { useIntl } from "react-intl";
import CoownershipCard from "../../components/CoownershipCard/CoownershipCard";
import { getOrdersList } from "../../services/api/api";
import UserCardList from "../../components/UserCardList/UserCardList";
import Button from "@material-ui/core/Button";
import OrderCardList from "../../components/OrderCardList/OrderCardList";

export const OrderList = ({ history }) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [showingUsers, setShowingUsers] = useState([]);
  const containerRef = useRef();
  const [snackbarConfig, setSnackbarConfig] = useState({
    message: "",
    open: false,
    action: null,
  });
  useEffect(() => {
    setLoading(true)
    getOrdersList().then((res) => {
      setOrders(res.data)
      setShowingUsers(res.data)
      setLoading(false)
    })
  }, [])
  const searchBarChange = (text) => {
    if(text.length === 0) {
      setShowingUsers(orders)
    } else {
      let newUsers = orders.filter((el) => {
        return el.user.username.indexOf(text) !== -1
      })
      setShowingUsers(newUsers)
    }
  }
  if(loading){
    return (
      <div>
        <Header history={history}/>
        <div style={{width: "100%", textAlign: "center", marginTop: 120}}>
        درحال بارگذاری
        </div>
      </div>
    )
  }
  if(orders.length === 0) {
    return (
      <div>
        <Header history={history}/>
        <div style={{width: "100%", textAlign: "center", marginTop: 120}}>
          The list is empty
        </div>
      </div>
    )
  }
  return (
    <div>
      <Header history={history}/>
      <div style={{padding: 24}}>
        {
          showingUsers.map((el) => {
            return (
              <OrderCardList
                confirmed={el.isEmailConfirmed}
                code={el.confirmEmailCode}
                id={el.id}
                name={el.student.firstName + " " + el.student.lastName}
                cellphone={el.cellphone}
                hasVisit 
                history={history}
                date={el.createdAt}
                price={el.total}
              />
            )
          })
        }
      </div>
    </div>
  );
};

export default OrderList;
