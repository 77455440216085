import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/Layout/Header/Header";
import { useIntl } from "react-intl";
import CoownershipCard from "../../components/CoownershipCard/CoownershipCard";
import UserCard from "../../components/UserCard/UserCard";
import UserMembershipDetail from "../../components/UserMembershipDetail/UserMembershipDetail";
import UserCoownerhipsDetail from "../../components/UserCoownerhipsDetail/UserCoownerhipsDetail";
import CoownerhipsCoownersDetail from "../../components/CoownerhipsCoownersDetail/CoownerhipsCoownersDetail";
import CoownerhipsTimeline from "../../components/CoownerhipsTimeline/CoownerhipsTimeline";
import { removeCoownership, getCoOwnerShipById, getOverview } from "../../services/api/api";

export const Overview = ({ history, match }) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(true);
  const containerRef = useRef();
  const [data, setData] = useState({});
  const [snackbarConfig, setSnackbarConfig] = useState({
    message: "",
    open: false,
    action: null,
  });

  useEffect(() => {
    const id = match.params.id;
    getOverview()
      .then((res) => {
        setLoading(false)
        setData(res.data)
      })
      .catch((err) => {
        history.goBack()
      })
  }, []);

  if(loading){
    return (
      <div>
        <Header history={history}/>
        <div style={{width: "100%", textAlign: "center", marginTop: 120, direction: "rtl"}}>
          در حال بارگزاری...
        </div>
      </div>
    )
  }
  return (
    <div>
      <Header history={history} />
      <div style={{ padding: 24, display: "flex", paddingBottom: 8 }}>
        <div style={{border: "solid 1px #aaa", borderRadius: 8, padding: 16, flex: 1, marginRight: 8, display: "flex", flexDirection: "column"}}>
          <div style={{color: "#009d69", fontSize: 18, fontWeight: "bold"}}>Coownerships</div>
          <div style={{marginTop: 16}}>Total Coownerships: <b>{data.allCoownership}</b></div>
          <div style={{marginTop: 8}}>Coownerships in draft phase: <b>{data.draftPhaseCoownership}</b></div>
          <div style={{marginTop: 8}}>Coownerships in edit phase: <b>{data.editPhaseCoownership}</b></div>
          <div style={{marginTop: 8}}>Coownerships in sign phase: <b>{data.signPhaseCoownership}</b></div>
          <div style={{marginTop: 8}}>Coownerships in final phase: <b>{data.finalPhaseCoownership}</b></div>
        </div>
        <div style={{border: "solid 1px #aaa", borderRadius: 8, padding: 16, flex: 1, marginLeft: 8, display: "flex", flexDirection: "column"}}>
          <div style={{color: "#009d69", fontSize: 18, fontWeight: "bold"}}>Users</div>
          <div style={{marginTop: 16}}>Total Users: <b>{data.allUsers}</b></div>
          <div style={{marginTop: 8}}>Users with confirmed email: <b>{data.confirmedUsers}</b></div>
          <div style={{marginTop: 8}}>Users with paid membership: <b>{data.paidMemberships}</b></div>
          <div style={{marginTop: 8}}>Users with at least 1 coownership: <b>{data.userThatHaveCoownership}</b></div>
        </div>
      </div>
      <div style={{ padding: 24, display: "flex", paddingBottom: 8, paddingTop: 8 }}>
        <div style={{border: "solid 1px #aaa", borderRadius: 8, padding: 16, flex: 1, marginRight: 8, display: "flex", flexDirection: "column"}}>
          <div style={{color: "#009d69", fontSize: 18, fontWeight: "bold"}}>Expenses</div>
          <div style={{marginTop: 16}}>Total Expenses submitted: <b>{data.totalExpense}</b></div>
        </div>
        <div style={{border: "solid 1px #aaa", borderRadius: 8, padding: 16, flex: 1, marginLeft: 8, display: "flex", flexDirection: "column"}}>
          <div style={{color: "#009d69", fontSize: 18, fontWeight: "bold"}}>Usages</div>
          <div style={{marginTop: 16}}>Total Usages submitted: <b>{data.totalUsages}</b></div>
        </div>
      </div>
      <div style={{ padding: 24, display: "flex", paddingBottom: 8, paddingTop: 8 }}>
        <div style={{border: "solid 1px #aaa", borderRadius: 8, padding: 16, flex: 1, display: "flex", flexDirection: "column"}}>
          <div style={{color: "#009d69", fontSize: 18, fontWeight: "bold"}}>Messages</div>
          <div style={{marginTop: 16}}>Total Messages sent: <b>{data.totalMessages}</b></div>
        </div>
        <div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
