import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/Layout/Header/Header";
import { useIntl } from "react-intl";
import CoownershipCard from "../../components/CoownershipCard/CoownershipCard";
import { getUsersList, downloadExcel } from "../../services/api/api";
import UserCardList from "../../components/UserCardList/UserCardList";
import Button from "@material-ui/core/Button";

export const UserList = ({ history }) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [showingUsers, setShowingUsers] = useState([]);
  const containerRef = useRef();
  const [snackbarConfig, setSnackbarConfig] = useState({
    message: "",
    open: false,
    action: null,
  });
  useEffect(() => {
    setLoading(true)
    getUsersList().then((res) => {
      setUsers(res.data)
      setShowingUsers(res.data)
      setLoading(false)
    })
  }, [])
  const downloadExcelhandle = () => {
    downloadExcel().then((res) => {
      console.log(res)
    })
  }
  const searchBarChange = (text) => {
    if(text.length === 0) {
      setShowingUsers(users)
    } else {
      let newUsers = users.filter((el) => {
        return el.user.username.indexOf(text) !== -1
      })
      setShowingUsers(newUsers)
    }
  }
  if(loading){
    return (
      <div>
        <Header history={history}/>
        <div style={{width: "100%", textAlign: "center", marginTop: 120}}>
        درحال بارگذاری
        </div>
      </div>
    )
  }
  if(users.length === 0) {
    return (
      <div>
        <Header history={history}/>
        <div style={{width: "100%", textAlign: "center", marginTop: 120}}>
          The list is empty
        </div>
      </div>
    )
  }
  return (
    <div>
      <Header history={history}/>
      <div style={{width: "100%", maxWidth: 900, margin: "auto", paddingTop: 32, display: "flex", flexDirection: "row"}}>
        {/* <Button color="primary" variant="contained" style={{height: 40, width: 200, marginRight: 20}} onClick={downloadExcelhandle}>Download excel</Button> */}
        <input placeholder="Search with email" style={{height: 40, flex: '1', paddingLeft: 12}} onChange={(e) => searchBarChange(e.target.value)}/>
      </div>
      <div style={{padding: 24}}>
        {
          showingUsers.map((el) => {
            return (
              <UserCardList
                confirmed={el.isEmailConfirmed}
                code={el.confirmEmailCode}
                id={el.id}
                name={el.firstName + " " + el.lastName}
                country={el.country}
                avatar={el.avatar}
                email={el.user.username}
                cellphone={el.cellphone}
                hasVisit 
                history={history}
              />
            )
          })
        }
      </div>
    </div>
  );
};

export default UserList;
