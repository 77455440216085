import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/Layout/Header/Header";
import { useIntl } from "react-intl";
import CoownershipCard from "../../components/CoownershipCard/CoownershipCard";
import UserCard from "../../components/UserCard/UserCard";
import UserMembershipDetail from "../../components/UserMembershipDetail/UserMembershipDetail";
import UserCoownerhipsDetail from "../../components/UserCoownerhipsDetail/UserCoownerhipsDetail";
import {
  addDiscount
} from "../../services/api/api";
import TextField from "@material-ui/core/TextField";
import ProfileImage from "../../assets/icons/upload.png";
import { Button } from "@material-ui/core";
import { makeNumbersLatin } from "../../handlers/util";

export const AddDiscount = ({ history, match }) => {
  const intl = useIntl();
  const formRef = useRef();

  const [loading, setLoading] = useState(false);
  const [articleImage, setArticleImage] = useState(null);
  const [code, setCode] = useState("");
  const [price, setPrice] = useState("");
  const [percent, setPercent] = useState("");

  const [snackbarConfig, setSnackbarConfig] = useState({
    message: "",
    open: false,
    action: null
  });

  const handleSubmitClick = e => {
    e.preventDefault();
    if (!formRef.current.checkValidity()) {
      console.log("hey");
    } else {
      addDiscount({
        code,
        price,
        percent,
        type: price.length !== 0 ? "price" : "percent"
      });
    }
  };
  return (
    <div>
      <Header history={history} />
      <b
        style={{
          width: "100%",
          maxWidth: 900,
          margin: "auto",
          paddingTop: 32,
          display: "flex",
          flexDirection: "row-reverse",
          justifyContent: "space-between",
          flexWrap: "wrap"
        }}
      >
        اضافه کردن کد تخفیف
      </b>
      <form
        ref={formRef}
        onSubmit={handleSubmitClick}
        style={{
          width: "100%",
          maxWidth: 900,
          margin: "auto",
          display: "flex",
          flexDirection: "row-reverse",
          justifyContent: "space-between",
          flexWrap: "wrap"
        }}
      >
        <TextField
          onChange={e => setCode(e.target.value)}
          id="outlined-basic"
          type="text"
          label="کد تخفیف"
          variant="outlined"
          required
          style={{
            marginTop: 24,
            direction: "rtl",
            width: "calc(100%)",
            textAlign: "right"
          }}
        />
        <TextField
          onChange={e => setPrice(e.target.value)}
          id="outlined-basic"
          type="text"
          label="قیمت"
          variant="outlined"
          style={{
            marginTop: 24,
            direction: "rtl",
            width: "calc(100%)",
            textAlign: "right"
          }}
        />
        <TextField
          onChange={e => setPercent(e.target.value)}
          InputProps={{ style: { direction: "rtl", textAlign: "right" } }}
          id="outlined-basic"
          type="text"
          label="درصد"
          multiline
          variant="outlined"
          style={{
            marginTop: 24,
            direction: "rtl",
            width: "calc(100%)",
            textAlign: "right"
          }}
        />
        <Button
          type="submit"
          color="primary"
          variant="contained"
          fullWidth
          style={{ height: 48 }}
        >
          ارسال
        </Button>
      </form>
    </div>
  );
};

export default AddDiscount;
