import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import CoownershipList from "./containers/Coownerships/List";
// import Login from "./containers/Login/Login";
import ScrollToTop from "./handlers/ScrollToTop";
import { ProtectedRoutes } from "./handlers/protected-routes";
import UserList from "./containers/Users/List";
import UserDetail from "./containers/Users/User";
import Login from "./containers/Login/Login";
import Coownership from "./containers/Coownerships/Coownership";
import LogsList from "./containers/Logs/List";
import Overview from "./containers/Detail/Detail";
import OrderList from "./containers/Orders/List";
import OneOrder from "./containers/Orders/Order";
import BooksList from "./containers/Books/List";
import AddBook from "./containers/Books/Book";
import AddArticle from "./containers/Article/Article";
import ArticleList from "./containers/Article/List";
import AddDiscount from "./containers/Discount/Discount";
import DiscountList from "./containers/Discount/List";

const RouterConfig = () => {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route exact path="/" component={Login} />
        <ProtectedRoutes>
          <Route exact path="/users/:id" component={UserDetail} />
          <Route exact path="/users" component={UserList} />

          <Route exact path="/orders/:id" component={OneOrder} />
          <Route exact path="/orders" component={OrderList} />

          <Route exact path="/books/add" component={AddBook} />
          <Route exact path="/books" component={BooksList} />

          <Route exact path="/articles/add" component={AddArticle} />
          <Route exact path="/articles" component={ArticleList} />

          <Route exact path="/discounts/add" component={AddDiscount} />
          <Route exact path="/discounts" component={DiscountList} />

          <Route exact path="/logs" component={LogsList} />
          <Route exact path="/overview" component={Overview} />
        </ProtectedRoutes>
      </Switch>
    </Router>
  );
};

export default RouterConfig;
