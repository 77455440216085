import React, { useContext, useState, useEffect } from "react";
import MySnackbar from "../components/MySnackbar/MySnackbar";

export function ProtectedRoutes({ children, location }) {
  const [snackbarConfig, setSnackbarConfig] = useState({
    message: "",
    open: false,
    action: null,
  });
  useEffect(() => {
    const token = localStorage.getItem("accessToken")
    if( !token ) {
      setSnackbarConfig({
        open: true,
        action: null,
        message: "You have to login to access this page!",
        showIcon: false,
        type: "error",
      });
      setTimeout(() => {
        window.location.href = "/login";
      }, 1000);
    }
  }, [location.pathname])
  return (
    <div>
      <MySnackbar
        {...snackbarConfig}
        setOpen={(open) => setSnackbarConfig({ ...snackbarConfig, open })}
      />
      {children}
    </div>
  );
}
