import React from "react";
import styles from "./CoownershipCard.module.scss";
import CarLogo from "../../assets/images/car.svg"
import OtherLogo from "../../assets/images/others.svg"
import Button from "@material-ui/core/Button";
import { apiUrl } from "../../constants/urls";

function CoownershipCard({ hasVisit, id, history, type, initiator, status, name, image, onDeleteClick, precislyId, assetType }) {
  return (
    <div className={styles.container}>
      {
        assetType == "car" ?
          <img src={CarLogo}/>
          :
          <img src={OtherLogo}/>
      }
      <div className={styles.innerContainer}>
        <div className={styles.leftInner}>
          <div style={{fontSize: 18, fontWeight: "bold"}}>{name}</div>
          <div>Type: {type}</div>
          <div>Initiator: {initiator}</div>
        </div>
        <div className={styles.rightInner}>
          <div>Status: {status}</div>
          {
            !hasVisit ?
              precislyId ? 
              <Button onClick={() => window.open(`https://app.precisely.se/projects/${precislyId}`, "_blank")} style={{minWidth: 160, marginBottom: 16, marginTop: 16, backgroundColor: "#175998", color: "white"}} variant="contained">
                Precisly
              </Button>
              :
              null
            : null
          }
          {
          hasVisit ? 
            <Button onClick={() => history.push(`/coownerships/${id}`)} style={{minWidth: 160}} color="primary" variant="contained">
              Visit
            </Button>
            : 
            <Button onClick={onDeleteClick} style={{minWidth: 160, backgroundColor: "#b00020", color: "white"}} variant="contained">
              Delete
            </Button>  
          }
        </div>
      </div>
    </div>
  )
}

export default CoownershipCard