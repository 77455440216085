import React from "react";
export default function SuccessIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="13"
      viewBox="0 0 17 13"
    >
      <g fill="none" fill-rule="evenodd">
        <path d="M0 0H24V24H0z" transform="translate(-4 -6)" />
        <path
          fill="#42CF97"
          fill-rule="nonzero"
          d="M19.995 6c-.26.008-.506.117-.688.303L9.015 16.596l-3.293-3.293c-.251-.261-.624-.366-.974-.275-.35.091-.624.365-.716.716-.091.35.014.722.275.973l4 4c.391.39 1.024.39 1.415 0l11-11c.295-.287.384-.727.223-1.107-.16-.38-.538-.622-.95-.61z"
          transform="translate(-4 -6)"
        />
      </g>
    </svg>
  );
}
