import React, { useEffect } from "react";
import styles from "./Header.module.scss";
import Logo from "../../../assets/icons/pingonio-white.svg"
import { getLogs } from "../../../services/api/api";

function Header({ history }) {
  const logssss = () => {
    getLogs()
      .then((res) => {
        let blob = new Blob([res.data], {type: 'text/plain'})
        let url = window.URL.createObjectURL(blob)
        window.open(url, "_blank")
      })
      .catch((err) => {
        console.log(err)
      });
  }
  return (
    <div className={styles.container}>
      <div className={styles.items}>
        <div onClick={() => history.push("/users")}>کاربران</div>
        <div onClick={() => history.push("/orders")}>خرید‌ها</div>
        <div onClick={() => history.push("/books")}>کتاب‌ها</div>
        <div onClick={() => history.push("/articles")}>مقالات</div>
        <div onClick={() => history.push("/discounts")}>تخفیف‌ها</div>
      </div>
      <img src={Logo}/>
    </div>
  )
}


function areEqual(prevProps, nextProps) {
  if(prevProps.title !== nextProps.title) {
    return false
  }
  return true;
}

export default React.memo(Header, areEqual);