const Messages = {
  "en": {

    //error section
    "INCORRECT_USERNAME_PASSWORD": "Incorrect username or password",
    "DUPLICATE_USERNAME": "This email is already taken",
    "DUPLICATE_CELLPHONE": "This phone number is already taken",
    "PASSWORD_IS_REQUIRED": "Please enter the password",
    "INVALID_PASSWORD_LENGTH": "The password must be longer than 8 chars",
    "INVALID_CELLPHONE": "The mobile number is invalid",
    "INVALID_EMAIL": "The email is invalid",
    "FULL_NAME_IS_REQUIRED": "",
    "YOU_ARE_NOT_AN_ADMIN": "You should be admin to access this page"
  },
  "se": {
    
    "subscribe.now": "Prenumerera nu",
    "subscriptions": "Prenumerationer",
    "special.plans": "Särskilda planer",
    "subscriptions.subtitle": "Välj rätt plan för dig",
  }
}

export default Messages
