import React, { useEffect, useState, memo } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";
import "./MySnackbar.css";
import { StylesProvider } from "@material-ui/core/styles";
import SuccessIcon from "../../assets/icons/SuccessIcon";

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}
const getSnackbarClassName = (type, action) => {
  if (!type) {
    return "mySnackbar" + (action ? "WithAction" : "WithoutAction");
  } else {
    if (type === "success")
      return "alertSnackbar" + (action ? "WithAction" : "WithoutAction");
  }
};

export const getSnackMessage = ({ message, type, showIcon }) => {
  if (!type) {
    return message;
  } else if (type === "success") {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {showIcon && (
          <div style={{ marginRight: 8 }}>
            <SuccessIcon />
          </div>
        )}
        <div>{message}</div>
      </div>
    );
  }
  return <div>{message}</div>
};
export const MySnackbar = ({
  open,
  onClose,
  message = "",
  action,
  autoHideDuration = 3000,
  // children,
  type = "",
  resumeHideDuration,
  setOpen,
  showIcon,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    setIsOpen(open);
  }, [open]);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <StylesProvider injectFirst>
      <Snackbar
        className={getSnackbarClassName(type, action)}
        TransitionComponent={SlideTransition}
        autoHideDuration={autoHideDuration}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={isOpen}
        onClose={handleClose}
        message={getSnackMessage({ message, type, showIcon })}
        action={action}
        key={"my-snackbar"}
        resumeHideDuration={resumeHideDuration}
      />
    </StylesProvider>
  );
};

function areEqual(prevProps, nextProps) {
  if (prevProps.open !== nextProps.open) {
    return false;
  }
  return true;
}
export default memo(MySnackbar, areEqual);
