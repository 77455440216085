export function generateArrayFromObject(object, options = {}) {
  let shouldParseInt = options.forceStringKey ? false : null;
  return Object.keys(object).map((key) => {
    if (shouldParseInt === null) {
      shouldParseInt = !isNaN(key);
    }
    return {
      key: shouldParseInt ? parseInt(key) : key,
      ...object[key],
    };
  });
}

export const scrollToRef = (ref, { top = 0 }) => {
  console.log(ref.current);
  window.scrollTo(0, ref.current.offsetTop - top);
};
export const focusAtRef = (ref) => ref.current.focus();

export function appendToUrl(url = "", id) {
  let stringId = id.toString();
  if (url.length === 0) {
    console.log("url can not be empty");
  } else {
    let lastChar = url.slice(-1);
    if (lastChar === "/") {
      return url + stringId;
    } else {
      return url + "/" + stringId;
    }
  }
}
export const boolValueOf = (string) => {
  if (string === "true") {
    return true;
  } else if (string === "false") {
    return false;
  } else {
    console.log("You are trying a to get bool value of a none boolean");
  }
};

let DateOption = {
  year: "numeric",
  month: "short",
  day: "numeric",
};

export function normalizeDate(date) {
  let normalizedDate = new Date(date);
  return normalizedDate.toLocaleDateString("en-US", DateOption);
}
export function normalizeTime(date) {
  let normalizedTime = new Date(date);
  return normalizedTime.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });
}

export function makeNumbersFarsi(str) {
  if(typeof str !== 'number' && typeof str !== 'string') {
    throw new Error('The input should be a string. it is ' + typeof str);
  }

  str = str.toString()

  var farsiNumbers = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
  for (var i = 0; i < 10; i++) {
    str = str.replace(RegExp(i.toString(), 'g'), farsiNumbers[i]);
  }
  return str;
}
export function makeNumbersLatin(str) {
  if(typeof str !== 'number' && typeof str !== 'string') {
    throw new Error('The input should be a string. it is ' + typeof str);
  }

  str = str.toString()

  var farsiNumbers = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
  var arabicNumbers = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
  for (var i = 0; i < 10; i++) {
    str = str.replace(RegExp(farsiNumbers[i], 'g'), i);
    str = str.replace(RegExp(arabicNumbers[i], 'g'), i);
  }
  return str;
}


export function priceNormalizer(price) {
  return price.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1/")
}
