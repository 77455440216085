import React from "react";
import styles from "./BookCardList.module.scss";
import AvatarLogo from "../../assets/images/avatar.svg"
import Button from "@material-ui/core/Button";
import { normalizeDate, makeNumbersFarsi, priceNormalizer } from "../../handlers/util";

const options = { year: 'numeric', month: 'long', day: 'numeric' };

function BookCardList({ abstract = "", id, history, name, email, cellphone, birthday, code, onDeleteClick, date }) {
  let newDate = new Date(date)
  return (
    <div className={styles.container}>
      <img src={`https://api.pingonio.com/books/${id}/cover`}/>
      <div className={styles.innerContainer}>
        <div className={styles.leftInner}>
          <b>{name}</b>
          <p style={{textAlign: "right", direction: "rtl"}}>{abstract.slice(0, 70)}...</p>
        </div>
        <div className={styles.rightInner}>
          <div></div>
          <Button onClick={() => window.open(`https://pingonio.com/books/${id}`, "_blank")} style={{minWidth: 160}} color="primary" variant="contained">
            بازدید
          </Button>
          <Button style={{minWidth: 160, marginTop: 12}} color="secondary" variant="contained">
            حذف
          </Button>
        </div>
      </div>
    </div>
  )
}


export default BookCardList;