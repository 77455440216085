import React from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Shabnam-FD-WOL',
    ].join(','),
  },
  palette: {
    error: {
      light: "#b00020",
      main: "#b00020",
      dark: "#b00020",
      contrastText: "#fff",
    },
    primary: {
      light: "#111",
      main: "#111",
      dark: "#111",
      contrastText: "#fff",
    },
    secondary: {
      light: "#aaa",
      main: "#aaa",
      dark: "#aaa",
      contrastText: "#000",
    },
  },
});

const AppThemeProvider = ({children}) => {
  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  );
};

export default AppThemeProvider;
