import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/Layout/Header/Header";
import { useIntl } from "react-intl";
import CoownershipCard from "../../components/CoownershipCard/CoownershipCard";
import { getDiscountsList } from "../../services/api/api";
import UserCardList from "../../components/UserCardList/UserCardList";
import Button from "@material-ui/core/Button";
import OrderCardList from "../../components/OrderCardList/OrderCardList";
import DiscountCard from "../../components/DiscountCard/DiscountCard";

export const DiscountList = ({ history }) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(true);
  const [discounts, setDiscounts] = useState([]);
  const containerRef = useRef();
  const [snackbarConfig, setSnackbarConfig] = useState({
    message: "",
    open: false,
    action: null
  });
  useEffect(() => {
    setLoading(true);
    getDiscountsList().then(res => {
      setDiscounts(res.data);
      setLoading(false);
    });
  }, []);
  if (loading) {
    return (
      <div>
        <Header history={history} />
        <div style={{ width: "100%", textAlign: "center", marginTop: 120 }}>
          درحال بارگذاری
        </div>
      </div>
    );
  }
  if (discounts.length === 0) {
    return (
      <div>
        <Header history={history} />
        <div style={{ width: "100%", textAlign: "center", marginTop: 120 }}>
          کد تخفیفی موجود نیست
        </div>
        <div style={{ textAlign: "center" }}>
          <Button
            onClick={() => history.push("/discounts/add")}
            color="primary"
            variant="contained"
            style={{ height: 48, paddingTop: 8, marginTop: 32}}
          >
            + اضافه کردن تخفیف
          </Button>
        </div>
      </div>
    );
  }
  return (
    <div>
      <Header history={history} />
      <div
        style={{
          width: "100%",
          maxWidth: 900,
          margin: "auto",
          paddingTop: 32,
          display: "flex",
          flexDirection: "row-reverse"
        }}
      ></div>
      <div style={{ padding: 24 }}>
        {discounts.map(el => {
          return (
            <DiscountCard
              id={el.id}
              code={el.code}
              percent={el.percent}
              price={el.price}
            />
          );
        })}
      </div>
      <div style={{ textAlign: "center" }}>
        <Button
          onClick={() => history.push("/discounts/add")}
          color="primary"
          variant="contained"
          style={{ height: 48, paddingTop: 8 }}
        >
          + اضافه کردن تخفیف
        </Button>
      </div>
    </div>
  );
};

export default DiscountList;
