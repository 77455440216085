import React, { useContext, useEffect, useState } from "react";

const GlobalStateContext = React.createContext();
const GlobalStateUpdateContext = React.createContext();

const initialState = {
  locale: localStorage.getItem("locale") || "en",
  email: "",
  isDark: false,
  unreadMessageCount: 0,
  userInfo: null,
};

export function GlobalStateProvider({ children }) {
  const [globalState, setGlobalState] = useState(initialState);
  function setWrapper(values) {
    setGlobalState({ ...globalState, ...values });
    if (values.locale) {
      localStorage.setItem("locale", values.locale);
    }
  }
  return (
    <GlobalStateContext.Provider value={globalState}>
      <GlobalStateUpdateContext.Provider value={setWrapper}>
        {children}
      </GlobalStateUpdateContext.Provider>
    </GlobalStateContext.Provider>
  );
}

export function useGlobalState() {
  return useContext(GlobalStateContext);
}
export function useGlobalStateUpdate() {
  return useContext(GlobalStateUpdateContext);
}
