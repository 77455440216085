import React from "react";
import styles from "./CoownerhipsTimeline.module.scss";
import AvatarLogo from "../../assets/images/avatar.svg"
import Button from "@material-ui/core/Button";
import AcceptedLogo from "../../assets/icons/accepted.svg"
import RejectedLogo from "../../assets/icons/rejected.svg"
import CreatedLogo from "../../assets/icons/created.svg"
import InvitedLogo from "../../assets/icons/invited.svg"
import EditedLogo from "../../assets/icons/edited.svg"
import SignedLogo from "../../assets/icons/signed.svg"
import { normalizeDate } from "../../handlers/util";

const getText = (type) => {
  switch (type) {
    case "create":
      return `Proposal created by `
    case "submit":
      return `Proposal submited by `
    case "invite":
      return `Proposal sent to `
    case "reject":
      return `Proposal rejected by `;
    case "edit":
      return `Proposal edited by `
    case "accept":
      return `Proposal accepted by `
    case "sign":
      return `Proposal signed by `
  }
};
const getIcon = (type) => {
  switch (type) {
    case "create":
      return CreatedLogo;
    case "submit":
      return CreatedLogo;
    case "invite":
      return InvitedLogo;
    case "reject":
      return RejectedLogo;
    case "edit":
      return EditedLogo;
    case "accept":
      return AcceptedLogo;
    case "sign":
      return SignedLogo;
  }
};

function CoownerhipsTimeline({ timeline }) {
  return (
    <div className={styles.container}>
      <div style={{fontSize: 20}}>Timeline: </div>
      <div style={{marginTop: 12}}>Here is the timeline of this coownership</div>
      <div className={styles.tableContainer}>
        {
          timeline.map((el) => {
            return (
              <div className={styles.row}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                  <img style={{marginRight: 20}} src={getIcon(el.type)}/>
                  <div>
                    {getText(el.type)} <b>{el.Coowner.name}</b>
                  </div>
                </div>
                <div>{normalizeDate(el.createdAt)}</div>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default CoownerhipsTimeline;