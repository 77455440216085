import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/Layout/Header/Header";
import { useIntl } from "react-intl";
import CoownershipCard from "../../components/CoownershipCard/CoownershipCard";
import { getCoOwnerShipsList, getLogs } from "../../services/api/api";

export const LogsList = ({ history }) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const containerRef = useRef();
  const [snackbarConfig, setSnackbarConfig] = useState({
    message: "",
    open: false,
    action: null,
  });


  if(loading){
    return (
      <div>
        <Header history={history}/>
        <div style={{width: "100%", textAlign: "center", marginTop: 120}}>
        درحال بارگذاری
        </div>
      </div>
    )
  }
  if(list.length === 0) {
    return (
      <div>
        <Header history={history}/>
        <div style={{width: "100%", textAlign: "center", marginTop: 120}}>
          The list is empty
        </div>
      </div>
    )
  }

  return (
    <div>
      <Header history={history} />
      <div style={{ padding: 24 }}>
        
      </div>
    </div>
  );
};

export default LogsList;
