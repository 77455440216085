import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/Layout/Header/Header";
import { useIntl } from "react-intl";
import CoownershipCard from "../../components/CoownershipCard/CoownershipCard";
import { getBooksList } from "../../services/api/api";
import UserCardList from "../../components/UserCardList/UserCardList";
import Button from "@material-ui/core/Button";
import OrderCardList from "../../components/OrderCardList/OrderCardList";
import BookCardList from "../../components/BookCardList/BookCardList";

export const BooksList = ({ history }) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(true);
  const [books, setBooks] = useState([]);
  const [showingUsers, setShowingUsers] = useState([]);
  const containerRef = useRef();
  const [snackbarConfig, setSnackbarConfig] = useState({
    message: "",
    open: false,
    action: null
  });
  useEffect(() => {
    setLoading(true);
    getBooksList().then(res => {
      setBooks(res.data);
      setShowingUsers(res.data);
      setLoading(false);
    });
  }, []);
  if (loading) {
    return (
      <div>
        <Header history={history} />
        <div style={{ width: "100%", textAlign: "center", marginTop: 120 }}>
          درحال بارگذاری
        </div>
      </div>
    );
  }
  if (books.length === 0) {
    return (
      <div>
        <Header history={history} />
        <div style={{ width: "100%", textAlign: "center", marginTop: 120 }}>
          The list is empty
        </div>
      </div>
    );
  }
  return (
    <div>
      <Header history={history} />
      <div
        style={{
          width: "100%",
          maxWidth: 900,
          margin: "auto",
          paddingTop: 32,
          display: "flex",
          flexDirection: "row-reverse"
        }}
      >
      </div>
      <div style={{ padding: 24 }}>
        {showingUsers.map(el => {
          return (
            <BookCardList
              id={el.id}
              name={el.title}
              cellphone={el.cellphone}
              hasVisit
              history={history}
              date={el.createdAt}
              abstract={el.abstract}
            />
          );
        })}
      </div>
      <div style={{textAlign: "center"}}>
        <Button
          onClick={() => history.push("/books/add")}
          color="primary"
          variant="contained"
          style={{ height: 48, paddingTop: 8 }}
        >
          + اضافه کردن کتاب
        </Button>
      </div>
    </div>
  );
};

export default BooksList;
